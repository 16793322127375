import * as React from "react";
import { Spinner } from "react-bootstrap";

const NotFoundPage = () => {
  const [isLoading, setisLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      <div className="clearfix" />
      <div className="mainsec">
        {isLoading ? (
          <div className="loadingSec">
            <Spinner animation="border" variant="dark" />{" "}
          </div>
        ) : (
          <div className="loadingSec">
            <p>Page Not Found !</p>
          </div>
        )}
      </div>
    </>
  );
};

export default NotFoundPage;
